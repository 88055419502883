export const routes = [
    {
        label: "Popular", 
        order: 1,
        options: [
              {
                title: "2007 Yamaha FZ6",
                url: "2007+yamaha+fz6",
                subtitle: "I still get a call from the CRPD about it every now and then.",
                bio: "Just fuckin send it!",
                img: 'https://lh3.googleusercontent.com/nAgwLRXIBjS9l1URqNfmgSjrgqBtDkeKyWOBwaMTqEYQalbA8N_TjHxsmqOzVKGU5pgjU8vAzvqCYnAoKZOgPo-8sJGlqbY_rPSHk6VytxqvVoiDQNvN20m5BzeaWV3xrk3sE_Sc4A=w2400',
                date: (1642355907728 + (((24 * 60 * 60 * 1000) * 7) * 8)),
              },
        ], 
        url: '/popular',
        subtitle: 'Most viewed posts site-wide',
        date: (1642355907728 + (((24 * 60 * 60 * 1000) * 7) * 2)),
        img: 'https://lh3.googleusercontent.com/mGNYaU-wZeB-9ygxv7W_7jI-pyVNzRZj7jfNWVqhHs-Y9LEAfof1NBNZjd0f8G7-dQ28cON3qewXRlwob03AE-a1-NHHo0g4WXFj5t9SvsAOM4ke69UQUsVFVocTFGDWQIUpljuYpQ=w2400'
    }, 
    {
        label: "Latest",
        order: 2, 
        options: [
            {
                title: "2018 Yamaha Star Venture TC",
                url: "2018+yamaha+star+venture+tc",
                subtitle: "Crazy awesome bike!",
                author: "Here Here",
                bio: "Live to ride, ride to live",
                img: 'https://lh3.googleusercontent.com/khId0PB0nfMWN3ven6UVwHLa4UAy6ZMZO7pJlC8YTd5j4Kp90JSnx5JL3pPG0SsF4R4ZXKN5U69PH1CKWnUqvatJTLbyjoH1hnN8F1HsWoIsPNtjokp0-NdiVS1ooRdF9yyQrqZb7Q=w2400',
                date: (1642355907728 + (((24 * 60 * 60 * 1000) * 7) * 5)),
            },
        ], 
        url: '/latest',
        subtitle: '15 newest additions to the collection',
        date: (1642355907728 + (((24 * 60 * 60 * 1000) * 7) * 2)),
        img: 'https://lh3.googleusercontent.com/3eYHWJazJqNFWe3fDGp_EWKn1GeL_QZcMOr7vOMCqdPL5pw3eCMOBwHbYP8DPC1y_9aoDnxKYVdjpHW4vmdC4cSa0uYW_iHzNPrhi84Ioatb5__o9_DiMrvEIqsXE0vTqxBGBIl16w=w2400',
    }, 
    {
        label: "Repair",
        order: 3, 
        options: [
            {
                title: "2007 Chevy 2500HD Duramax",
                url: "2007+chevy+2500hd+duramax",
                subtitle: "My 4 wheel drive baby",
                author: "Author Reads",
                bio: "This is the Author's bio",
                img: 'https://lh3.googleusercontent.com/vZ0_0coNcwkE5mip46OyP6-NAbVdUNpb-qwuwcrvzuHwZzH_CKKP9XLgzm8NsKl5dM0ae8o5h0PKYr6i1JFUOvi_pqy5bPjYQXGQQLVFg3okIgWCNt4umau4flXp8DMwP1DeOSzBlg=w2400',
                date: 1642355907728,
            },
            {
                title: "2009 Chevy Impala ls",
                url: "2009+chevy+impala+ls",
                subtitle: "Made about $1000 on this gambel",
                author: "Crazy Tech",
                bio: "Can't argue with success",
                img: 'https://lh3.googleusercontent.com/HbbbBMCC3cky0rwzmRqWA_fuQxJ2wn-1n5MfqBnkQFs-IOlanh2Q9GIftYuy1BVPYy6Mno5PDv4kke1GDcJIPIvwQNIxcA8pwAxuR8K6V6SSmcjKR-4mp17fpj7p4z5cIYIwetwPcQ=w2400',
                date: (1642355907728 + (((24 * 60 * 60 * 1000) * 7) * 5)),
            },
        ], 
        url: '/repair',
        subtitle: 'Articles written to provide general as well as vehical spacific repair procedures',
        date: (1642355907728 + (((24 * 60 * 60 * 1000) * 7) * 2)),
        img: 'https://lh3.googleusercontent.com/E6s0oK5x1Ff3PFR0MwVT_Lz7C5Mc0EBvUU-EY3GOpP1wzuY6scHOxzZIiJenmjD6wsevLJwMbJsRK-KLSb_-9mMyGnV_1a2Wq5zxcqIVcGdAhQacehn9gZToE3hIlRnFzHou2oTJmg=w2400',
    }, 
    {
        label: "Fabrication",
        order: 4, 
        options: [
            
            {
                title: "1979 Chevy Camaro T-top Conversion",
                url: "1979+chevy+camaro+t-top+con",
                subtitle: "From solid top to T-top",
                author: "Yos truly",
                bio: "Bio Bio Bio Hazzard!",
                img: 'https://lh3.googleusercontent.com/COz4qsKe6_1SK47eqxguMagHrerQqoFwrnFl_ZTWChdLVWvn_9JBugTCKaNSag-BxirLL-kb2CymhhEMP7EWFXUbnhbtIsXMkQhKHAMrjxkuFs_fWDlfs6rGvZdy8UajZ2SdD61wkw=w2400',
                date: (1642355907728 + (((24 * 60 * 60 * 1000) * 7) * 4)),
              },
        ], 
        url: '/fab',
        subtitle: "Fabrication projects I've taken on",
        date: (1642355907728 + (((24 * 60 * 60 * 1000) * 7) * 2)),
        img: 'https://lh3.googleusercontent.com/ijejdxES9M-jtDx_QnZaOCeaffxux0wSGwy-JFNRhHwN7x3dMbJhTNbv4GymoMSUltJt_hNSfwUzqP-JXz9klPJbmPMI7B7LqZ9EX32kqp3PFMuYs2q4odp4zAiDZXdVI8iLjLpZKA=w2400',
    }, 
    {
        label: "Reviews",
        order: 5, 
        options: [
            {
                title: "The best cooling system tool I didn't know I needed",
                url: "vaccum+filling+the+cooling+system",
                subtitle: "My only regret was not buying one sooner",
                author: "Country Coder",
                bio: "Always reach higher",
                img: 'https://lh3.googleusercontent.com/pp00EhYg2Oxov0UW06yJIDARIT_1DaiP2cOHikpbgtYNamfEkM--mBSPeftn1Giz6pWaEIr51LkM7X7qUF7smhtD-CbPXv5xlQbVN0fO6QP16zBlrZJkxItHN5OTrVD_Grdh_yv7pg=w2400',
                date: (1642355907728 + (((24 * 60 * 60 * 1000) * 7) * 2)),

            },
            {
                title: "Serpentine Belt Tentioner Wrench",
                url: "serpentine+belt+tentioner+wrench",
                subtitle: "A ratchet will work, till it doesn't",
                author: "Country Coder",
                bio: "Always reach higher",
                img: 'https://lh3.googleusercontent.com/kO1orN_9AcnbG3yTNUkfFLSe3Gd7f0NR2PbojzZsOsqjbNoFQKuxUSNsmakZsNCRU_DN1V6jctoYzEImgjKwcVvLuL4naGVkUrvPJB7hckMxSPHHV-v8CxpoZfSqJiBHagPuKlWt1w=w2400',
                date: (1642355907728 + ((24 * 60 * 60 * 1000) * 7)),
            },
            {
              title: "Duramax Harmonic Balancer Removal Kit",
              url: "duramax+harmonic+balancer+removal+kit",
              subtitle: "Made simple work of a daunting task",
              author: "Country Coder",
              bio: "Always Reach Higher",
              img: 'https://lh3.googleusercontent.com/QCg6K0dPt3w_t8Y8kHgeF9C_WFFUVMwoCuhdqnXHMHoXd0vL6WeS0GKHT0QST50jRVXffvlE4Y8wsaPyAbpBxFZzzZ6E-SOJ-DfIDJmnFJ5SuR5lLZB2SIz4S_ome2jvFCDEpY55Rw=w2400',
              date: (1642355907728 + ((24 * 60 * 60 * 1000) * 7)),
            },
             
        ], 
        url: '/reviews',
        subtitle: 'Reviews of specialty tools and products I use in the shop',
        date: (1642355907728 + (((24 * 60 * 60 * 1000) * 7) * 2)),
        img:'https://lh3.googleusercontent.com/PPfUIV03ZHv5pI3bZ7kcAy8yTVENlG_AfaT_0ODGW3naxYF3e-6BW72-u6AF777EAYZbT3VbnnPHyry6BXdSmrupLjYNh2Hs3FCuLn-wzue2JskTtvShgadeawXHKx7aVjbNuCgMtg=w2400',
    },
]