export const articles = [
    {
      title: "Serpentine Belt Tentioner Wrench",
      url: "serpentine+belt+tentioner+wrench",
      subtitle: "A ratchet will work, till it doesn't",
      author: "Country Coder",
      bio: "Always reach higher",
      overview: "Cooling system leaks can ",
      pros: [
        {title: '3/8" and 1/2" drive option.', body: "Having both drive sizes gives this kit more utility and with specialty tools espessially the more utility the better!"},
        {title: "Low Profile", body: "All you need to release a self-tensioning serp belt is a ratchet right? Well if you're reading this you prbably already know serpentine belt tentioners can be in some terrible places. Depending on the vehical a few millimeters of tool width can be the difference between just removing the belt and taking half the engine bay apart to remove the belt like on my LMM Duramax."},
        {title: "Adjustability", body: "Again those tentioners can be in some hard to reach places. This kit comes with a 3/8'' to 1/2'' throw link. the 3/8'' side of this link is 12 point to allow setting the link to an offset angel from the main tool. This allows, for example, to reach the tentioner from the opposite side of the engine fan."},
      ],
      cons: [
        {title: "Fairly basic kit", body: "Not nessesarily a con as much as a nature of the beast. There are other kits out there that come with say, more attchments, more/better sockets, or maybe a case for the kit. At then end of the day you get what yopu pay for weather you need everything you pay for or not."}, 
      ],
      conclusion: "I hated on these tools for a long time, but since buying and using this kit I choose it over a ratchet everytime I remove or install a serpentine belt!",
      link: "https://www.amazon.com/gp/product/B004KED77A/ref=as_li_tl?ie=UTF8&tag=countrycoder-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B004KED77A&linkId=9d9ddc62456ac0b4835bca9a13645f51",
      imgs: [
        'https://lh3.googleusercontent.com/kO1orN_9AcnbG3yTNUkfFLSe3Gd7f0NR2PbojzZsOsqjbNoFQKuxUSNsmakZsNCRU_DN1V6jctoYzEImgjKwcVvLuL4naGVkUrvPJB7hckMxSPHHV-v8CxpoZfSqJiBHagPuKlWt1w=w2400',
        'https://lh3.googleusercontent.com/PWLC7C0jtCcRnTWrhEEPjqQA5wSNFw2OSXczGwtHDVxON5IZX-DYM8LC26SIgUcKdmIm1LCF674y5ouO2d5wJ9fCZhGe3ZkAVQcQJM24wIH4-7X2HQBG-ALoIj6GPcoZ-OyJKcKIKQ=w2400',  
        'https://lh3.googleusercontent.com/09QyQTEpYhCOcQFstql6C-DIvf9WnEeTNeBQYd26-tswX6zfZiqr41ruNVvArbPJPMCkKeWiY6mTcEdMe2DHgqPIc2AmccxvMhVDchPQrudMwtn9AvA716cy4lwQuv7ZaMkfcF1L1g=w2400',  
      ],
      date: (1642355907728 + (((24 * 60 * 60 * 1000) * 7) * 2)),
      format: 'review',
    },
    {
      title: "The best cooling system tool I didn't know I needed",
      url: "vaccum+filling+the+cooling+system",
      subtitle: "My only regret is not buying one sooner",
      author: "Country Coder",
      bio: "Always reach higher",
      overview: "Cooling system leaks can ",
      pros: [{title: "Drain and cover coolant in a clean container for reuse.", body: "Remove the lower cover"}, {title: "Remove upper radiator hose", body: "Hose clamp, wire thing"}],
      cons: [{title: "Under whelming venturi assembly.", body: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda, reiciendis in mollitia provident repellat, consectetur corporis corrupti asperiores blanditiis earum pariatur similique. Vero ex harum id qui maiores architecto nisi. Hose clamp, wire thing"}, {title: "Con 2", body: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda, reiciendis in mollitia provident repellat, consectetur corporis corrupti asperiores blanditiis earum pariatur similique. Vero ex harum id qui maiores architecto nisi. Hose clamp, wire thing"}],
      conclusion: "Cooling system leaks can ",
      link: "https://www.amazon.com/gp/product/B08X7FXR9V/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B08X7FXR9V&linkCode=as2&tag=countrycoder-20&linkId=6fcdb3f723309790ac199a5034e9304f",
      imgs: [
        'https://lh3.googleusercontent.com/pp00EhYg2Oxov0UW06yJIDARIT_1DaiP2cOHikpbgtYNamfEkM--mBSPeftn1Giz6pWaEIr51LkM7X7qUF7smhtD-CbPXv5xlQbVN0fO6QP16zBlrZJkxItHN5OTrVD_Grdh_yv7pg=w2400',
        'https://lh3.googleusercontent.com/Jz6dk384rB-R6hJhhXpQpmUin757fMqawWC5dElkI_Q47QuoyAW-ghce4F83I_9JDxgAOiX3tbJL3Kh1-iffkuV_AQvO55FIpKeEEvBuCSzwgumrf0W8b9xXBPK42Q_DvVZ6cayfTA=w2400',
      ],
      date: (1642355907728 + (((24 * 60 * 60 * 1000) * 7) * 2)),
      format: 'review',
    },
    {
      title: "Duramax Harmonic Balancer Removal Kit",
      url: "duramax+harmonic+balancer+removal+kit",
      subtitle: "Made simple work of a daunting task",
      author: "Country Coder",
      bio: "Always Reach Higher",
      imgs: [
        'https://lh3.googleusercontent.com/QCg6K0dPt3w_t8Y8kHgeF9C_WFFUVMwoCuhdqnXHMHoXd0vL6WeS0GKHT0QST50jRVXffvlE4Y8wsaPyAbpBxFZzzZ6E-SOJ-DfIDJmnFJ5SuR5lLZB2SIz4S_ome2jvFCDEpY55Rw=w2400',
        'https://lh3.googleusercontent.com/cjnRiDzK1HcTVEOe3Xq_DeaMnQbCk_d5ZQjLqx91Zk_W7grNJNRYaGX2UQ_2shtV1cQokVOQxkutILfJI2hr6eX_nThGWwXgaZQNywUM7WgU2l4cKEuDFJLDTyiRTeiHbitINt0OxA=w2400',

      ],
      overview: "Cooling system leaks can ",
      pros: [{title: "Drain and cover coolant in a clean container for reuse.", body: "Remove the lower cover"}, {title: "Remove upper radiator hose", body: "Hose clamp, wire thing"}],
      cons: [{title: "Under whelming venturi assembly.", body: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda, reiciendis in mollitia provident repellat, consectetur corporis corrupti asperiores blanditiis earum pariatur similique. Vero ex harum id qui maiores architecto nisi. Hose clamp, wire thing"}, {title: "Con 2", body: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda, reiciendis in mollitia provident repellat, consectetur corporis corrupti asperiores blanditiis earum pariatur similique. Vero ex harum id qui maiores architecto nisi. Hose clamp, wire thing"}],
      conclusion: "Cooling system leaks can ",
      link: "https://www.amazon.com/gp/product/B07B4WKDDR/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07B4WKDDR&linkCode=as2&tag=countrycoder-20&linkId=fec9dd03d39fff4d9973101352b0dc6c",
      format: 'review',
      date: (1642355907728 + ((24 * 60 * 60 * 1000) * 7)),
    },
    {
      title: "2007 Chevy 2500HD Duramax",
      url: "2007+chevy+2500hd+duramax",
      subtitle: "My 4 wheel drive baby",
      author: "Author Reads",
      bio: "This is the Author's bio",
      imgs: [
        'https://lh3.googleusercontent.com/vZ0_0coNcwkE5mip46OyP6-NAbVdUNpb-qwuwcrvzuHwZzH_CKKP9XLgzm8NsKl5dM0ae8o5h0PKYr6i1JFUOvi_pqy5bPjYQXGQQLVFg3okIgWCNt4umau4flXp8DMwP1DeOSzBlg=w2400',

      ],
      date: 1642355907728,
      format: 'sbs',
      overview: "Overview",
      tools: ['wrench', 'impact'],
      steps: [{title: "Drain and cover coolant in a clean container for reuse.", body: "Remove the lower cover"}, {title: "Remove upper radiator hose", body: "Hose clamp, wire thing"}],
      keyPoints: ["Mating surfaces should be clean and smooth", "sdfhsjkhf sdjhfk"],
    },
    {
      title: "1979 Chevy Camaro T-top Conversion",
      url: "1979+chevy+camaro+t-top+con",
      subtitle: "From solid top to T-top",
      author: "Yos truly",
      bio: "Bio Bio Bio Hazzard!",
      body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda ipsum rerum error? Dolores eum repellat aut sapiente accusamus quae, voluptates odio harum ratione vitae dicta facere vel perspiciatis vero molestias!",
      imgs: [
        'https://lh3.googleusercontent.com/COz4qsKe6_1SK47eqxguMagHrerQqoFwrnFl_ZTWChdLVWvn_9JBugTCKaNSag-BxirLL-kb2CymhhEMP7EWFXUbnhbtIsXMkQhKHAMrjxkuFs_fWDlfs6rGvZdy8UajZ2SdD61wkw=w2400',
      ],
      date: (1642355907728 + (((24 * 60 * 60 * 1000) * 7) * 4)),
      format: 'writeUp'
    },
    {
      title: "2018 Yamaha Star Venture TC",
      url: "2018+yamaha+star+venture+tc",
      subtitle: "Crazy awesome bike!",
      author: "Here Here",
      bio: "Live to ride, ride to live",
      imgs: [
        'https://lh3.googleusercontent.com/khId0PB0nfMWN3ven6UVwHLa4UAy6ZMZO7pJlC8YTd5j4Kp90JSnx5JL3pPG0SsF4R4ZXKN5U69PH1CKWnUqvatJTLbyjoH1hnN8F1HsWoIsPNtjokp0-NdiVS1ooRdF9yyQrqZb7Q=w2400',
      ],
      date: (1642355907728 + (((24 * 60 * 60 * 1000) * 7) * 5)),
    },
    
    {
      title: "2007 Yamaha FZ6",
      url: "2007+yamaha+fz6",
      subtitle: "I still get a call from the CRPD about it every now and then.",
      author: "McLovin",
      bio: "Just fuckin send it!",
      imgs: [
      'https://lh3.googleusercontent.com/nAgwLRXIBjS9l1URqNfmgSjrgqBtDkeKyWOBwaMTqEYQalbA8N_TjHxsmqOzVKGU5pgjU8vAzvqCYnAoKZOgPo-8sJGlqbY_rPSHk6VytxqvVoiDQNvN20m5BzeaWV3xrk3sE_Sc4A=w2400',
      ],
      date: (1642355907728 + (((24 * 60 * 60 * 1000) * 7) * 8)),
    },
    {
      title: "2009 Chevy Impala ls",
      url: "2009+chevy+impala+ls",
      subtitle: "Made about $1000 on this gambel",
      author: "Crazy Tech",
      bio: "Can't argue with success",
      format: 'sbs',
      overview: "Overview",
      tools: ['wrench', 'impact'],
      steps: [{title: "Drain and cover coolant in a clean container for reuse.", body: "Remove the lower cover"}, {title: "Remove upper radiator hose", body: "Hose clamp, wire thing"}],
      keyPoints: ["Mating surfaces should be clean and smooth", "sdfhsjkhf sdjhfk"],
      imgs: [
        'https://lh3.googleusercontent.com/HbbbBMCC3cky0rwzmRqWA_fuQxJ2wn-1n5MfqBnkQFs-IOlanh2Q9GIftYuy1BVPYy6Mno5PDv4kke1GDcJIPIvwQNIxcA8pwAxuR8K6V6SSmcjKR-4mp17fpj7p4z5cIYIwetwPcQ=w2400',
      ],
      date: (1642355907728 + (((24 * 60 * 60 * 1000) * 7) * 5)),
    },
    {
      title: "Case IH 2388 ",
      url: "case+ih+2388",
      subtitle: "Got some age on her but she's a hard runnin' unit",
      author: "Goofy A. Fucker",
      bio: "Shac-a-duce!",
      imgs: [
        'https://lh3.googleusercontent.com/uV6X3OCcsIOANTstxxJBM-9oa3V3NQ_9cFS5mvJ73tH-tFuwUe_DTIICYxO3Q3AhYj0qdUI3VuY0Q5dcf4hYHAER2jA6QA-DFGzdHZknbijkqHC9098vNQxsJnCrt1Odh5FVzPb51g=w2400',
      ],
      date: (1642355907728 + (((24 * 60 * 60 * 1000) * 7) * 2)),
    },
  ];
